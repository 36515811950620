import React, { Component } from 'react';
import { Link } from 'gatsby';
import { LESSONS_PLAN } from '../../../../../constants/routes';

import ChotyryPlus from './ChotyryPlus';
import YakHratysya from './YakHratysya';
import ShchoDali from './ShchoDali';
import Chudovo from './Chudovo';
import VyajavlyayetsyaMozhemoITry from './VyajavlyayetsyaMozhemoITry';
import YakschoVazhkoZbahnutyPryntzyp from './YakschoVazhkoZbahnutyPryntzyp';
import ZnayemoSchoRobyty from './ZnayemoSchoRobyty';
import Molodets from './Molodets';
import SohodniNoveZavdannya from './SohodniNoveZavdannya';
import YakPravylno from './YakPravylno';
import VyrizhIZahadayDruzyam from './VyrizhIZahadayDruzyam';
import YakPochynavsyaRebusMetod from './YakPochynavsyaRebusMetod';
import DytsadokUMinsku from './DytsadokUMinsku';
import Podobayetsya from './Podobayetsya';
import PodarunkoviUrokyZakinchylysya from './PodarunkoviUrokyZakinchylysya';
import YakSpravy from './YakSpravy';
import PoradyAvtora from './PoradyAvtora';
import NaSyomyjDenZavedenoVidpochyvaty from './NaSyomyjDenZavedenoVidpochyvaty';
import DavnoNePidvyshchuvalySkladnist from './DavnoNePidvyshchuvalySkladnist';
import ShchosNovenke from './ShchosNovenke';
import IZnovuDenVidpochynku from './IZnovuDenVidpochynku';
import VzheYTretiyTyzhdenPozadu from './VzheYTretiyTyzhdenPozadu';
import ANeTakVzheYBahatoLyshylosya from './ANeTakVzheYBahatoLyshylosya';
import DyakuyuShchoMyBulyRazom from './DyakuyuShchoMyBulyRazom';

import './styles.scss';

const DynamicArticle = ({ props }) => {
  const components = {
    ChotyryPlus: ChotyryPlus,
    YakHratysya: YakHratysya,
    ShchoDali: ShchoDali,
    Chudovo: Chudovo,
    VyajavlyayetsyaMozhemoITry: VyajavlyayetsyaMozhemoITry,
    YakschoVazhkoZbahnutyPryntzyp: YakschoVazhkoZbahnutyPryntzyp,
    ZnayemoSchoRobyty: ZnayemoSchoRobyty,
    Molodets: Molodets,
    SohodniNoveZavdannya: SohodniNoveZavdannya,
    YakPravylno: YakPravylno,
    VyrizhIZahadayDruzyam: VyrizhIZahadayDruzyam,
    YakPochynavsyaRebusMetod: YakPochynavsyaRebusMetod,
    DytsadokUMinsku: DytsadokUMinsku,
    Podobayetsya: Podobayetsya,
    PodarunkoviUrokyZakinchylysya: PodarunkoviUrokyZakinchylysya,
    YakSpravy: YakSpravy,
    PoradyAvtora: PoradyAvtora,
    NaSyomyjDenZavedenoVidpochyvaty: NaSyomyjDenZavedenoVidpochyvaty,
    DavnoNePidvyshchuvalySkladnist: DavnoNePidvyshchuvalySkladnist,
    ShchosNovenke: ShchosNovenke,
    IZnovuDenVidpochynku: IZnovuDenVidpochynku,
    VzheYTretiyTyzhdenPozadu: VzheYTretiyTyzhdenPozadu,
    ANeTakVzheYBahatoLyshylosya: ANeTakVzheYBahatoLyshylosya,
    DyakuyuShchoMyBulyRazom: DyakuyuShchoMyBulyRazom,
  };
  let Article = props ? components[props.templateId] : null;
  return props ? (
    <div className="wrapper">
      <Link to={LESSONS_PLAN} activeClassName="active">
        <div className="close-button">&nbsp;</div>
      </Link>
      <Article props={props} />
    </div>
  ) : null;
};

export default DynamicArticle;
