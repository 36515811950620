import React, { Component } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {
  FREE_LESSONS,
  LESSONS_PLAN,
} from '../../../../constants/routes';
import { navigate } from 'gatsby';

import Card from './Card';
import './styles.scss';

class Cards extends Component {
  state = {
    cardAddress: this.props.cardAddress,
    lesson: this.props.lesson,
    articles: this.props.articles,
    globals: this.props.globals,
    cunningMode: localStorage.getItem('cunningMode') === 'true',
    study: this.props.authUser.study,
  };

  endLesson = () => {
    navigate(LESSONS_PLAN);
    // TODO: Implement greetings!
    console.log('Ти завершив урок!');
  };

  next = (id) => {
    if (this.slider.props.children.length === parseInt(id)) {
      this.endLesson();
    } else {
      this.slider.slickNext();
    }
  };

  goTo = (index) => {
    this.slider.slickGoTo(index - 1);
    navigate(
      `${FREE_LESSONS}/${this.state.cardAddress.lessonId}/${index}`,
    );
  };

  toggleCunningMode = (uid, study, firebase) => {
    this.setState({ cunningMode: !this.state.cunningMode });
    localStorage.setItem('cunningMode', !this.state.cunningMode);
    firebase && firebase.doDBFieldUpdate(`users/${uid}/study`, {
        ...study,
        ...{ cunningMode: !study.cunningMode },
      });
  };

  beforeChange = (oldIndex, newIndex) => {
    // if it's an already seen lesson
    // and it's not the last lesson
    // and the user is moving forward
    if (
      +this.state.cardAddress.lessonId ===
        +this.state.study.availableLesson &&
      +this.state.cardAddress.lessonId !==
        this.props.globals.maxLesson &&
      this.state.study.availableCard < newIndex + 1 &&
      oldIndex < newIndex
    ) {
      let nextAvailableLesson = +this.state.cardAddress.lessonId;
      let nextAvailableCard = oldIndex !== 0 ? newIndex + 1 : 1;
      // overwrite, if it's the last card
      if (
        newIndex + 1 === this.state.lesson.cards.length &&
        oldIndex !== 0
      ) {
        nextAvailableLesson = +this.state.cardAddress.lessonId + 1;
        nextAvailableCard = 1;
      }
      this.props.firebase.doDBFieldUpdate(
        `users/${this.props.authUser.uid}/study/availableLesson`,
        nextAvailableLesson,
      );
      this.props.firebase.doDBFieldUpdate(
        `users/${this.props.authUser.uid}/study/availableCard`,
        nextAvailableCard,
      );
      const newState = {
        study: {
          ...this.state.study,
          ...{ availableCard: nextAvailableCard },
        },
      };
      this.setState(newState);
    }
    if (oldIndex > 1 && newIndex === 0) {
      this.endLesson();
    } else if (oldIndex === 0 && newIndex > 1) {
      navigate(LESSONS_PLAN);
    }
  };

  afterChange = (index) => {
    history.replaceState(
      null,
      '',
      `${FREE_LESSONS}/${this.state.cardAddress.lessonId}/${
        index + 1
      }/`,
    );
  };

  render() {
    const {
      lesson,
      articles,
      globals,
      firebase,
      authUser,
    } = this.props;
    let settings = {
      dots: false,
      arrows: true,
      adaptiveHeight: true,
      focusOnSelect: false,
      infinite: true,
      speed: 250,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      initialSlide: this.state.cardAddress.cardId - 1,
      beforeChange: (newIndex, oldIndex) => {this.beforeChange(newIndex, oldIndex, firebase)},
      afterChange: this.afterChange,
    };
    const cardAddress = this.state.cardAddress;
    const lessonId = this.state.lesson.id;
    return cardAddress.lessonId <= authUser.payments.payedLesson ? (
      <div className="site-content" data-site-content>
        {+cardAddress.lessonId === lessonId ? (
          <Slider
            ref={(slider) => (this.slider = slider)}
            {...settings}
            onReInit={() => {
              // fixing 3rd party component layout issue
              // setting this parameter in styles ruins initial renderring
              // setting it here fixes vertical alignment
              document.querySelector(
                '[data-site-content]',
              ).style.display = 'flex';
            }}
          >
            {lesson.cards.map((card) => {
              card.article =
                card.type === 'info-page'
                  ? articles[card.templateId]
                  : null;
              card.next = this.next;
              card.goTo = this.goTo;
              card.globals = globals;
              return (
                <Card
                  key={card.id}
                  card={card}
                  cardAddress={cardAddress}
                  authUser={authUser}
                  cunningMode={this.state.cunningMode}
                  toggleCunningMode={this.toggleCunningMode}
                />
              );
            })}
          </Slider>
        ) : (
          <span>Завантаження...</span>
        )}
      </div>
    ) : (
      <div className="unpaid">Неоплачений урок 😔</div>
    );
  }

  static propTypes = {
    lesson: PropTypes.object,
    cardAddress: PropTypes.object,
  };
}

export default Cards;
