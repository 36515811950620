import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withFirebase } from '../../../../utils/Firebase';
import Header from './components/Header';
import DynamicArticle from './article-templates/DynamicArticle';
import Domino from './components/Domino';
import Syllables from './components/Syllables';
import QuizImage from './components/QuizImage';
import EyeOpened from './../../../../assets/svg/eye-opened.svg';
import EyeClosed from './../../../../assets/svg/eye-closed.svg';

import './styles.scss';

class Card extends Component {
  render() {
    const { cardAddress, card, style } = this.props;
    const cunningMode = this.props.cunningMode;
    const cunningModeImage = cunningMode ? EyeClosed : EyeOpened;
    const cunningModeCaption = cunningMode
      ? 'Вимкнути' + ' «Хитрун-режим»'
      : 'Увімкнути' + ' «Хитрун-режим»';

    return (
      <div className="lesson-container" style={style}>
        <div className={'card-container ' + card.type}>
          {card.type == 'info-page' ? (
            <DynamicArticle props={card} />
          ) : (
            <div className="wrapper">
              <Header
                lessonNumber={cardAddress.lessonId}
                type={card.type}
              />
              {card.type === 'read-and-point' ||
              card.type === 'key-read-and-point' ? (
                <div>
                  <img
                    src={cunningModeImage}
                    className="cunning-mode-button"
                    alt={cunningModeCaption}
                    title={cunningModeCaption}
                    onClick={() => {
                      this.props.toggleCunningMode(
                        this.props.authUser.uid,
                        this.props.authUser.study,
                        this.props.firebase,
                      );
                    }}
                  />
                  {card.type === 'key-read-and-point' ? (
                    <Domino
                      syllables={card.keys}
                      size="md"
                      keysCunningMode={cunningMode}
                    />
                  ) : null}
                </div>
              ) : null}
              {card.type === 'syllables' ? (
                <Domino syllables={card.syllables} size="lg" />
              ) : null}
              {card.type.indexOf('read') >= 0 ? (
                <Syllables word={card.word} type={card.type} />
              ) : null}
              <div className="basic-letters-center-wrapper">
                <ul className="basic-letters" />
              </div>
              {card.type.indexOf('point') >= 0 ? (
                <QuizImage
                  word={card.word}
                  next={(id) => card.next(card.id)}
                  quizCunningMode={cunningMode}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withFirebase(Card);
