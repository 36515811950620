import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Letters = ({ text, size }) => {
  return text ? (
    <div className={'letters ' + size + ' '}>
      <svg viewBox="0 0 100 100">
        <text textAnchor="middle" x="50%" y="65%">
          {text}
        </text>
      </svg>
    </div>
  ) : null;
};

Letters.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default Letters;
