import React from 'react';
import { LESSONS_PLAN } from '../../../../../../constants/routes';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import './styles.scss';

//move to Firebase
//add transcribed lesson numbers
//and 'Урок'
const taskDescriptions = {
  syllables: 'послухай слова, назви картинки і букви під ними',
  read: 'назви картинки, а потім літери під цими картинками',
  'read-and-point': 'прочитай ребус і вкажи правильний малюнок',
  'key-read-and-point':
    'прочитай за допомогою ключа і вкажи правильний малюнок',
};

const Header = ({ lessonNumber, type }) => {
  return type ? (
    <div className="header">
      <span>
        <strong>Урок {lessonNumber}:</strong> {taskDescriptions[type]}
      </span>
      <Link to={LESSONS_PLAN} activeClassName="active">
        <div className="close-button">&nbsp;</div>
      </Link>
    </div>
  ) : null;
};

Header.propTypes = {
  lessonNumber: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Header;
