import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const PoradyAvtora = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10PoradyAvtora)}</h1>
      <p>
        <strong>{renderHTML(strings._20ShcheRazNahaduy)}</strong>{' '}
        {renderHTML(strings._30YakshchoDytynaN)}
      </p>
      <p>
        {renderHTML(strings._40UsnaHraOznachay)}
        <strong>{renderHTML(strings._50DytynaNeLysheRo)}</strong>
        {renderHTML(strings._60YakiYiyZahaduye)}
        <strong>{renderHTML(strings._70NavchylasyaSama)}</strong>{' '}
        {renderHTML(strings._80BudLaskaPrysvya)}
        <strong>{renderHTML(strings._90TsyaNayprostish)}</strong>{' '}
        {renderHTML(strings._100PoPersheMalyuk)}
        <strong>{renderHTML(strings._110UDytynyRozvyvay)}</strong>
        {renderHTML(strings._120PoTretyeOzvuch)}
        <strong>
          {renderHTML(strings._130URozshyrennomuK)}
        </strong>{' '}
        {renderHTML(strings._140VYakyhKartynkyP)}
        <strong>{renderHTML(strings._150KilkaRazivVymov)}</strong>
        {renderHTML(strings._160DytynaVidrazu)}
        <strong>{renderHTML(strings._170Rozumiye)}</strong>
        {renderHTML(strings._180YakKilkaLiterZl)}
        <strong>{renderHTML(strings._190Zapamyatovuye)}</strong>
        {renderHTML(strings._200YakVinVyhlyaday)}
      </p>
      <br />
      SLIDER
      <br />
      <p>
        <strong>{renderHTML(strings._210OtzheUsnaHraMay)}</strong>
      </p>
      <ul>
        <li>{renderHTML(strings._220DoroslyyZahaduy)}</li>
        <li>{renderHTML(strings._230DytynaZahaduyeU)}</li>
      </ul>
      <p>{renderHTML(strings._240UPershomuVarian)}</p>
      <p>{renderHTML(strings._250UDruhomuVariant)}</p>
      <p>
        {renderHTML(strings._260IMozheteButyVpe)}
        <strong>
          {renderHTML(strings._270NavchyvshysHrat)}
        </strong>{' '}
        {renderHTML(strings._280IStaneZahaduvat)}
      </p>
      <NextSlideButton
        text={strings._290ProdovzhytyNavc}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

PoradyAvtora.propTypes = {
  props: PropTypes.object.isRequired,
};

export default PoradyAvtora;
