import React, { Component } from 'react';
import Layout from '../utils/layout';
import LessonBase from '../components/scenes/Lesson/Lesson';
import { compose } from 'recompose';
import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';

const condition = (authUser) => !!authUser;

const LessonPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(LessonBase);

export class Lesson extends Component {
  render() {
    const { uri } = this.props;
    const preloadedLesson = this.props.pageContext.lessonObject;
    const preloadedArticles = this.props.pageContext.articles;
    const preloadedGlobals = this.props.pageContext.globals;

    const uriItems = uri.split('/');
    const lessonId = uriItems[uriItems.length - 2];
    const cardId = uriItems[uriItems.length - 1];
    const cardAddress = { lessonId, cardId };

    const isLoaded = !!(
      parseInt(lessonId) === parseInt(preloadedLesson.id)
    );

    return (
      <Layout hideSecondaryNav>
        <LessonPage
          isLoaded={isLoaded}
          cardAddress={cardAddress}
          preloadedLesson={preloadedLesson}
          preloadedArticles={preloadedArticles}
          preloadedGlobals={preloadedGlobals}
        />
      </Layout>
    );
  }
}

export default Lesson;
