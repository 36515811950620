import React, { Component } from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';

import './styles.scss';

class ExpandableContent extends Component {
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };
  }

  toggleExpanded = () => {
    this.setState((state) => {
      return { isExpanded: !state.isExpanded };
    });
  };

  render() {
    return (
      <div className="expandable-content">
        <span onClick={this.toggleExpanded}>
          {renderHTML(this.props.teaser)}
        </span>
        {this.state.isExpanded ? (
          <div>{renderHTML(this.props.content)}</div>
        ) : null}
      </div>
    );
  }

  static propTypes = {
    teaser: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };
}

export default ExpandableContent;
