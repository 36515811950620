import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const VyajavlyayetsyaMozhemoITry = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10VyyavlyayetsyaM)}</h1>

      <p>{renderHTML(strings._20ZChotyrmaBudeSk)}</p>
      <p>
        <strong className="highlight">
          {renderHTML(strings._30NapolehlyvoRadz)}
        </strong>
      </p>
      <p>{renderHTML(strings._40IzPoyavoyuZorov)}</p>
      <p>{renderHTML(strings._50SkilkyChasuNamN)}</p>

      <NextSlideButton
        text={strings._60ZakinchytyUrok}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

VyajavlyayetsyaMozhemoITry.propTypes = {
  props: PropTypes.object.isRequired,
};

export default VyajavlyayetsyaMozhemoITry;
