import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const SohodniNoveZavdannya = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10SohodniNoveZavd)}</h1>

      <p>
        <strong>{renderHTML(strings._20ChytayemoSlovoZ)}</strong>{' '}
        {renderHTML(strings._30NeVhadaly)}
        <br />
        <strong>{renderHTML(strings._40NayholovnisheZa)} </strong>
      </p>

      <p>{renderHTML(strings._50DorosliMozhutKo)}</p>

      <NextSlideButton text={strings._60Rozpochaty} card={props} />

      <h1>{renderHTML(strings._70OITakChastoPyta)}</h1>

      <p>
        <strong>{renderHTML(strings._80SkilkyVsohoUrok)}</strong>
      </p>
      <p>
        {renderHTML(strings._90UPochatkovomuKu)}{' '}
        <strong>{renderHTML(strings._100VisimPodarunkov)}</strong>{' '}
        {renderHTML(strings._110VonySamiPoSobiH)}
      </p>
      <p>
        <strong>{renderHTML(strings._120SplatytyVyZmozh)}</strong>{' '}
        {renderHTML(strings._130CherezPryvat24N)}{' '}
      </p>
      <p>{renderHTML(strings._140UsiChekyElektro)}</p>
      <p>
        {renderHTML(strings._150DetalnisheProRo)}{' '}
        {props.globals.contactPhoneNumber}.{' '}
      </p>
      <p>{renderHTML(strings._160MyVidvoliklysya)}</p>
      <p>{renderHTML(strings._170UchenUzheZachek)}</p>

      <NextSlideButton
        text={strings._180RozpochatyUrok}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

SohodniNoveZavdannya.propTypes = {
  props: PropTypes.object.isRequired,
};

export default SohodniNoveZavdannya;
