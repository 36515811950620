import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import GoToLessonsPlanButton from './../../../../../components/atoms/Buttons/GoToLessonsPlanButton';

const YakschoVazhkoZbahnutyPryntzyp = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10YakshchoVazhkoZbahnuty)}</h1>

      <p>
        <strong>{renderHTML(strings._20InodiDitkamVazh)} </strong>{' '}
        {renderHTML(strings._30ChastoTakeTrapl)}{' '}
        <strong>{renderHTML(strings._40UTsomuVypadkuVa)}</strong>
      </p>
      <ul>
        <li>
          {renderHTML(strings._50PoPershe)}{' '}
          <strong>{renderHTML(strings._60PoyasnitDytyni)}</strong>{' '}
          {renderHTML(strings._70ShchoURiznyhIho)}
        </li>
        <li>
          {renderHTML(strings._80PoDruheMozhnaP)}{' '}
          <strong>{renderHTML(strings._90PohratyZDytynoy)}</strong>{' '}
          {renderHTML(strings._100MAMaskaLILiyk)}
        </li>
      </ul>

      <p>
        {renderHTML(strings._110ShcheProstisheD)}{' '}
        <strong>{renderHTML(strings._120NabirRiznomanit)}</strong>{' '}
        {renderHTML(strings._130SpochatkuVyRozh)}
      </p>
      <p>
        <strong>{renderHTML(strings._140TakBudeLehshe)}</strong>
      </p>

      <GoToLessonsPlanButton
        text={renderHTML(strings._150DoPlanuUrokiv)}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

YakschoVazhkoZbahnutyPryntzyp.propTypes = {
  props: PropTypes.object.isRequired,
};

export default YakschoVazhkoZbahnutyPryntzyp;
