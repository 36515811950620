import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import Image from '../../../../atoms/Image';
import GoToLessonsPlanButton from './../../../../../components/atoms/Buttons/GoToLessonsPlanButton';

const Molodets = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10Molodets)}</h1>

      <p>
        <strong>{renderHTML(strings._20Vidpochyvayemo)}</strong>{' '}
        {renderHTML(strings._30Nahaduyu)}{' '}
        <strong> {renderHTML(strings._40NeVartoPospisha)}</strong>{' '}
        {renderHTML(strings._50ShcheYeChasDoP)}{' '}
        <strong>{renderHTML(strings._60NalashtovuytesN)}</strong>
      </p>
      <p>
        {renderHTML(strings._70APokyShcho)}{' '}
        <strong>{renderHTML(strings._80PodyvitsyaNaNas)}</strong>{' '}
        {renderHTML(strings._90DoRechiOtiDviyk)}{' '}
        <strong>{renderHTML(strings._100YaChytayu)}</strong>
      </p>
      <Image
        filename="rebus-ditky.jpg"
        alt={strings._105NashiUchni}
        title={strings._105NashiUchni}
      />
      <p>{renderHTML(strings._110VykladayteIVySv)} </p>

      <GoToLessonsPlanButton
        text={renderHTML(strings._120ZakinchytyUrok)}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

Molodets.propTypes = {
  props: PropTypes.object.isRequired,
};

export default Molodets;
