import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import GoToLessonsPlanButton from './../../../../../components/atoms/Buttons/GoToLessonsPlanButton';

const VyrizhIZahadayDruzyam = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10VyrizhIZahadayD)}</h1>

      <p>
        <strong>{renderHTML(strings._20AShchoYakMalyuk)}</strong>
      </p>
      <p>
        {renderHTML(strings._30Vidkryyte)}
        {renderHTML(strings._40FaylykIzRebusam)}
        {renderHTML(strings._50RozdrukuyteYoho)}
        <strong>{renderHTML(strings._60VperedDo)}</strong>{' '}
        {renderHTML(strings._70ChudovaVpravaI)}{' '}
      </p>
      <p>
        <strong>{renderHTML(strings._80VyUzheZnayetePr)}</strong>
      </p>
      <GoToLessonsPlanButton
        text={renderHTML(strings._90ZavershytyUrok)}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

VyrizhIZahadayDruzyam.propTypes = {
  props: PropTypes.object.isRequired,
};

export default VyrizhIZahadayDruzyam;
