import React from 'react';

import './../styles.scss';

const GoToSlideButton = ({ text, card, destinationSlide }) => {
  return (
    <a
      onClick={() => card.goTo(destinationSlide)}
      className="slide-button"
    >
      {text}
    </a>
  );
};

export default GoToSlideButton;
