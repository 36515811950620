import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const DavnoNePidvyshchuvalySkladnist = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10DavnoNePidvyshc)}</h1>

      <p>
        <strong>{renderHTML(strings._20MyDavnoOpanuval)}</strong>
      </p>

      <ul>
        <li>{renderHTML(strings._30PivenRakMisya)}</li>
        <li>{renderHTML(strings._40BantykRakBant)}</li>
        <li>{renderHTML(strings._50AnhelNapiyNap)}</li>
        <li>{renderHTML(strings._60BantykTankRem)}</li>
      </ul>

      <p>{renderHTML(strings._70VazhkoDityZabuv)}</p>

      <NextSlideButton
        text={strings._80ProdovzhytyNavc}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

DavnoNePidvyshchuvalySkladnist.propTypes = {
  props: PropTypes.object.isRequired,
};

export default DavnoNePidvyshchuvalySkladnist;
