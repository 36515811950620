import React, { Component } from 'react';
import { withFirebase } from '../../../utils/Firebase';
import Cards from './Cards/Cards';
import { AuthUserContext } from '../../../utils/Session';

class Lesson extends Component {
  _initFirebase = false;

  state = {
    firebaseLesson: null,
    firebaseArticles: null,
    firebaseGlobals: null,
    loading: true,
  };

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.getLessonContent();
    }
  };

  componentDidMount() {
    const { isLoaded } = this.props;
    if (!isLoaded) {
      this.firebaseInit();
    }
  }

  componentDidUpdate() {
    const { isLoaded } = this.props;
    if (!isLoaded) {
      this.firebaseInit();
    }
  }

  getLessonContent = async () => {
    const { firebase, cardAddress } = this.props;

    await firebase
      .database()
      .ref(`/lessons/${cardAddress.lessonId}`)
      .once('value')
      .then(async (snapshot) => {
        const firebaseLesson = await snapshot.val();
        this.setState({
          firebaseLesson,
        });
      });
    await firebase
      .database()
      .ref('/articles')
      .once('value')
      .then(async (snapshot) => {
        const firebaseArticles = await snapshot.val();
        this.setState({
          firebaseArticles,
          loading: false,
        });
      });
    await firebase
      .database()
      .ref('/globals')
      .once('value')
      .then(async (snapshot) => {
        const firebaseGlobals = await snapshot.val();
        this.setState({
          firebaseGlobals,
          loading: false,
        });
      });
  };

  render() {
    const {
      loading,
      firebaseLesson,
      firebaseArticles,
      firebaseGlobals,
    } = this.state;
    const {
      isLoaded,
      cardAddress,
      preloadedLesson,
      preloadedArticles,
      preloadedGlobals,
    } = this.props;

    // у такому місці за бажання, можна гратися із спінерами, показуючи їх до завантаження контенту
    // але краще комбінувати із пререндером, оскільки це певно, краще для пошукової оптимізації (коментарі користувачів — чудовий контент)
    const finalLesson = isLoaded ? preloadedLesson : firebaseLesson;
    const finalArticles = isLoaded
      ? preloadedArticles
      : firebaseArticles;
    const finalGlobals = isLoaded
      ? preloadedGlobals
      : firebaseGlobals;

    if (!isLoaded && loading) return null;

    return (
      <div className="main">
        <AuthUserContext.Consumer>
          {(authUser) => (
            <Cards
              cardAddress={cardAddress}
              lesson={preloadedLesson}
              articles={preloadedArticles}
              globals={preloadedGlobals}
              authUser={authUser}
              firebase={this.props.firebase}
            />
          )}
        </AuthUserContext.Consumer>
      </div>
    );
  }
}
Lesson.contextType = AuthUserContext;

export default withFirebase(Lesson);
