import React, { Component } from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';
import ExpandableContent from './../../../../molecules/ExpandableContent';

const ChotyryPlus = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._05ChotyryPlyus)}</h1>

      <p>{renderHTML(strings._10BudLaskaNeHrayt)}</p>
      <ExpandableContent
        teaser={strings._20DokladnisheProT}
        content={
          '<p>' +
          strings._30OsoblyvostiRozv +
          '</p><p>' +
          strings._40YakshchoMalecha +
          '</p>'
        }
      />
      <p>{renderHTML(strings._50YakshchoZhVyVzh)}</p>
      <NextSlideButton text={strings._60PereytyDali} card={props} />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

ChotyryPlus.propTypes = {
  props: PropTypes.object.isRequired,
};

export default ChotyryPlus;
