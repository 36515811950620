import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import GoToLessonsPlanButton from './../../../../../components/atoms/Buttons/GoToLessonsPlanButton';

const DyakuyuShchoMyBulyRazom = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10DyakuyuShchoMyB)}</h1>
      <p>
        <strong>{renderHTML(strings._20VitayuVyZakinch)}</strong>
      </p>
      <p>{renderHTML(strings._30ChyNePohodylysB)}</p>
      <ul>
        <li>{renderHTML(strings._40ShchoDalosyaLeh)}</li>
        <li>{renderHTML(strings._50YakiBulySumnivy)}</li>
        <li>{renderHTML(strings._60YakiUVasPotochn)}</li>
      </ul>
      <p>{renderHTML(strings._70BudLaskaPodilit)}</p>
      <p>
        <strong class="highlight">
          {renderHTML(strings._80Novynka)}
        </strong>
        <strong>{renderHTML(strings._90RebusMetodBezk)}</strong>{' '}
        {renderHTML(strings._100ZnimitVideoVid)}
      </p>
      <p>{renderHTML(strings._110ZvyazhitsyaZNa)}</p>
      HyperComments
      <GoToLessonsPlanButton
        text={renderHTML(strings._120DoPlanuUrokiv)}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

DyakuyuShchoMyBulyRazom.propTypes = {
  props: PropTypes.object.isRequired,
};

export default DyakuyuShchoMyBulyRazom;
