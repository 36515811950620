import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const YakPravylno = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10YakPravylno)}</h1>

      <p>
        <strong>{renderHTML(strings._20InodiDorosliNav)}</strong>
      </p>
      <p>
        <strong>{renderHTML(strings._30DuzhePoshyrenoy)}</strong>{' '}
        {renderHTML(strings._40YeSprobaDoroslo)}
        <strong>{renderHTML(strings._50UdarniHolosni)}</strong>{' '}
        {renderHTML(strings._60DohovoryuyemoSl)}{' '}
      </p>
      <p>
        {renderHTML(strings._70VrahuyteShchoDi)}
        <strong>{renderHTML(strings._80UDytynyHe)}</strong>{' '}
        {renderHTML(strings._90IVonaChastoHus)}{' '}
      </p>
      <p>
        {renderHTML(strings._100YakshchoUDytyny)}
        <strong>
          {renderHTML(strings._110PohrayteUzvorot)}
        </strong>{' '}
        {renderHTML(strings._120OpysanuNaprykin)}
      </p>

      <h1>{renderHTML(strings._130Uporayetsya)}</h1>

      <p>
        <strong>{renderHTML(strings._140InshoyuPoshyren)}</strong>{' '}
        {renderHTML(strings._150YeStrahZapropon)}
        <strong>
          {renderHTML(strings._160UzheNaPershomuZ)}
        </strong>{' '}
        {renderHTML(strings._170HochaBTroshkyTa)}
      </p>
      <p>
        <strong>{renderHTML(strings._180UporayetsyaZnak)}</strong>
      </p>

      <NextSlideButton text={strings._190Poyihaly} card={props} />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

YakPravylno.propTypes = {
  props: PropTypes.object.isRequired,
};

export default YakPravylno;
