import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const DominoLetters = ({ text, size, isConsonant }) => {
  return text ? (
    <div className={'domino-letters ' + size + isConsonant}>
      <svg viewBox="0 0 100 70">
        <text textAnchor="middle" x="50%" y="50%">
          {text}
        </text>
      </svg>
    </div>
  ) : null;
};

DominoLetters.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  isConsonant: PropTypes.string.isRequired,
};

export default DominoLetters;
