const transliterateDictionary = {
  А: 'a',
  Б: 'b',
  В: 'v',
  Г: 'h',
  Д: 'd',
  Е: 'e',
  Ж: 'zh',
  З: 'z',
  И: 'y',
  Й: 'y',
  К: 'k',
  Л: 'l',
  М: 'm',
  Н: 'n',
  О: 'o',
  П: 'p',
  Р: 'r',
  С: 's',
  Т: 't',
  У: 'u',
  Ф: 'f',
  Х: 'kh',
  Ц: 'ts',
  Ч: 'ch',
  Ш: 'sh',
  Щ: 'shch',
  Ъ: '',
  Ы: 'y',
  Ь: '',
  Э: 'e',
  Ю: 'yu',
  Я: 'ya',
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'h',
  д: 'd',
  е: 'e',
  ж: 'zh',
  з: 'z',
  и: 'y',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'kh',
  ц: 'ts',
  ч: 'ch',
  ш: 'sh',
  щ: 'shch',
  ъ: 'y',
  ы: 'y',
  ь: '',
  э: 'e',
  ю: 'yu',
  я: 'ya',
  Є: 'ye',
  є: 'ye',
  Ї: 'yi',
  ї: 'yi',
  І: 'i',
  і: 'i',
  Ґ: 'g',
  ґ: 'g',
  '.': '',
  '’': ',',
  ' ': '_',
  ',': '_',
  '/': '_',
};

const transliteratedIdDictionary = {
  а: 'a',
  б: 'b',
  'б’я': 'b,ya',
  ба: 'ba',
  бе: 'be',
  би: 'by',
  бі1: 'bi1',
  бі: 'bi',
  бо: 'bo',
  бу: 'bu',
  в: 'v',
  'в’ї': 'v,yi',
  'в’я': 'v,ya',
  ва1: 'va1',
  ва: 'va',
  ве: 've',
  ви: 'vy',
  ві: 'vi',
  во: 'vo',
  ву: 'vu',
  г: 'h',
  га: 'ha',
  ги: 'hy',
  гі: 'hi',
  го: 'ho',
  гу: 'hu',
  д: 'd',
  да: 'da',
  де: 'de',
  ди1: 'dy1',
  ди: 'dy',
  ді1: 'di1',
  ді: 'di',
  до: 'do',
  ду1: 'du1',
  ду: 'du',
  дя: 'dya',
  дь: "d'",
  є: 'ye',
  ж: 'zh',
  жа: 'zha',
  же: 'zhe',
  жи: 'zhy',
  жі: 'zhi',
  жо: 'zho',
  жу: 'zhu',
  з: 'z',
  за: 'za',
  зе: 'ze',
  зи: 'zy',
  зо: 'zo',
  зу: 'zu',
  зь: "z'",
  зьо: 'zyo',
  й: 'y',
  і: 'i',
  ї1: 'yi1',
  ї: 'yi',
  к: 'k',
  ка: 'ka',
  ке: 'ke',
  ки1: 'ky1',
  ки: 'ky',
  кі: 'ki',
  кі1: 'ki1',
  ко: 'ko',
  ку: 'ku',
  л: 'l',
  ла: 'la',
  ле: 'le',
  ли: 'ly',
  лі: 'li',
  ло: 'lo',
  лу: 'lu',
  лю: 'lyu',
  ля: 'lya',
  ль: "l'",
  льо: 'lyo',
  м: 'm',
  'м’я': 'm,ya',
  ма: 'ma',
  ме: 'me',
  ми: 'my',
  мі: 'mi',
  мо: 'mo',
  му: 'mu',
  н: 'n',
  на: 'na',
  ни: 'ny',
  ні1: 'ni1',
  ні: 'ni',
  но: 'no',
  ня: 'nya',
  нь: "n'",
  о1: 'o1',
  о: 'o',
  п: 'p',
  'п’я': 'p,ya',
  па: 'pa',
  пе: 'pe',
  пи: 'py',
  пі: 'pi',
  по: 'po',
  пу: 'pu',
  р: 'r',
  'р’ї': 'r,yi',
  'р’я': 'r,ya',
  ра: 'ra',
  ре: 're',
  ри: 'ry',
  рі: 'ri',
  ро: 'ro',
  ру: 'ru',
  рю: 'ryu',
  ря: 'rya',
  с: 's',
  са: 'sa',
  се: 'se',
  си: 'sy',
  сі: 'si',
  со: 'so',
  су: 'su',
  ся: 'sya',
  сь: "s'",
  т: 't',
  та: 'ta',
  те: 'te',
  ти: 'ty',
  ті: 'ti',
  то: 'to',
  ту: 'tu',
  тю: 'tyu',
  ть: "t'",
  у: 'u',
  ф: 'f',
  фа: 'fa',
  фе: 'fe',
  фо: 'fo',
  х: 'kh',
  ха: 'kha',
  хи: 'khy',
  хо: 'kho',
  ху: 'khu',
  ц: 'ts',
  ца: 'tsa',
  це: 'tse',
  ци: 'tsy',
  ці: 'tsi',
  ця: 'tsya',
  ць: "ts'",
  цьо: 'tsyo',
  ч: 'ch',
  ча: 'cha',
  че1: 'che1',
  че: 'che',
  чи: 'chy',
  чі: 'chi',
  чо: 'cho',
  ш: 'sh',
  'ш’ю': 'sh,yu',
  ша1: 'sha1',
  ша: 'sha',
  ши: 'shy',
  шо: 'sho',
  шу: 'shu',
  щ: 'shch',
  щи: 'shchy',
  щі: 'shchi',
  щу: 'shchu',
  я: 'ya',
  А: 'a2',
  Б: 'b2',
  В: 'v2',
  Г: 'h2',
  Д: 'd2',
  Є: 'ye2',
  Ж: 'zh2',
  З: 'z2',
  Й: 'y2',
  І: 'i2',
  Ї: 'yi2',
  К: 'k2',
  Л: 'l2',
  М: 'm2',
  Н: 'n2',
  О: 'o2',
  П: 'p2',
  Р: 'r2',
  С: 's2',
  Т: 't2',
  У: 'u2',
  Ф: 'f2',
  Х: 'kh2',
  Ц: 'ts2',
  Ч: 'ch2',
  Ш: 'sh2',
  Щ: 'shch2',
  Я: 'ya2',
  БА: 'ba2',
  БЕ: 'be2',
  БИ: 'by2',
  БІ: 'bi2',
  БО: 'bo2',
  БУ: 'bu2',
  ВА: 'va2',
  ВЕ: 've2',
  ВИ: 'vy2',
  ВІ: 'vi2',
  ВО: 'vo2',
  ВУ: 'vu2',
  ГА: 'ha2',
  ГИ: 'hy2',
  ГІ: 'hi2',
  ГО: 'ho2',
  ГУ: 'hu2',
  ДА: 'da2',
  ДЕ: 'de2',
  ДИ: 'dy2',
  ДІ: 'di2',
  ДО: 'do2',
  ДУ: 'du2',
  ДЯ: 'dya2',
  ДЬ: "d'2",
  ЖА: 'zha2',
  ЖЕ: 'zhe2',
  ЖИ: 'zhy2',
  ЖІ: 'zhi2',
  ЖО: 'zho2',
  ЖУ: 'zhu2',
  ЗА: 'za2',
  ЗЕ: 'ze2',
  ЗИ: 'zy2',
  ЗО: 'zo2',
  ЗУ: 'zu2',
  ЗЬ: "z'2",
  КА: 'ka2',
  КЕ: 'ke2',
  КИ: 'ky2',
  КІ: 'ki2',
  КО: 'ko2',
  КУ: 'ku2',
  ЛА: 'la2',
  ЛЕ: 'le2',
  ЛИ: 'ly2',
  ЛІ: 'li2',
  ЛО: 'lo2',
  ЛУ: 'lu2',
  ЛЮ: 'lyu2',
  ЛЯ: 'lya2',
  ЛЬ: "l'2",
  МА: 'ma2',
  МЕ: 'me2',
  МИ: 'my2',
  МІ: 'mi2',
  МО: 'mo2',
  МУ: 'mu2',
  НА: 'na2',
  НИ: 'ny2',
  НІ: 'ni2',
  НО: 'no2',
  НЯ: 'nya2',
  НЬ: "n'2",
  ПА: 'pa2',
  ПЕ: 'pe2',
  ПИ: 'py2',
  ПІ: 'pi2',
  ПО: 'po2',
  ПУ: 'pu2',
  РА: 'ra2',
  РЕ: 're2',
  РИ: 'ry2',
  РІ: 'ri2',
  РО: 'ro2',
  РУ: 'ru2',
  РЮ: 'ryu2',
  РЯ: 'rya2',
  СА: 'sa2',
  СЕ: 'se2',
  СИ: 'sy2',
  СІ: 'si2',
  СО: 'so2',
  СУ: 'su2',
  СЯ: 'sya2',
  СЬ: "s'2",
  ТА: 'ta2',
  ТЕ: 'te2',
  ТИ: 'ty2',
  ТІ: 'ti2',
  ТО: 'to2',
  ТУ: 'tu2',
  ТЮ: 'tyu2',
  ТЬ: "t'2",
  ФА: 'fa2',
  ФЕ: 'fe2',
  ФО: 'fo2',
  ХА: 'kha2',
  ХИ: 'khy2',
  ХО: 'kho2',
  ХУ: 'khu2',
  ЦА: 'tsa2',
  ЦЕ: 'tse2',
  ЦИ: 'tsy2',
  ЦІ: 'tsi2',
  ЦЯ: 'tsya2',
  ЦЬ: "ts'2",
  ЧА: 'cha2',
  ЧЕ: 'che2',
  ЧИ: 'chy2',
  ЧІ: 'chi2',
  ЧО: 'cho2',
  ША: 'sha2',
  ШИ: 'shy2',
  ШО: 'sho2',
  ШУ: 'shu2',
  ЩИ: 'shchy2',
  ЩІ: 'shchi2',
  ЩУ: 'shchu2',
  'Б’Я': 'b,ya2',
  'В’Ї': 'v,yi2',
  'В’Я': 'v,ya2',
  ЗЬО: 'zyo2',
  ЛЬО: 'lyo2',
  'М’Я': 'm,ya2',
  'П’Я': 'p,ya2',
  'Р’Ї': 'r,yi2',
  'Р’Я': "ts'2",
  ЦЬО: 'tsyo2',
  'Ш’Ю': 'sh,yu2',
};

const wordBySyllableDictionary = {
  а: 'ангел',
  б: 'Б',
  'б’я': 'Б’Я',
  ба: 'бантик',
  бе: 'беркут',
  би: 'бик',
  бі1: 'білка',
  бі: 'біг',
  бо: 'борщ',
  бу: 'бублик',
  в: 'В',
  'в’ї': 'В’Ї',
  'в’я': 'В’Я',
  'в’є': 'В’Є',
  ва1: 'ваза',
  ва: 'вафля',
  ве: 'вежа',
  ви: 'вишня',
  ві: 'віник',
  во: 'вовк',
  ву: 'вузол',
  г: 'Г',
  га: 'гайка',
  ги: 'гиря',
  гі: 'гілка',
  го: 'голка',
  гу: 'губи',
  д: 'Д',
  да: 'дах',
  де: 'дерево',
  ди1: 'диня',
  ди: 'дим',
  ді1: 'дірка',
  ді: 'дівчинка',
  до: 'дошка',
  ду1: 'душ',
  ду: 'дудка',
  дя: 'дятел',
  дь: 'ДЬ',
  є: 'єнот',
  ж: 'Ж',
  жа: 'жабка',
  же: 'жезл',
  жи: 'жито',
  жі: 'жінка',
  жо: 'жолудь',
  жу: 'жуйка',
  з: 'З',
  за: 'заєць',
  зе: 'зебра',
  зі: 'зірка',
  зи: 'зимородок',
  зо: 'зошит',
  зу: 'зуби',
  зь: 'ЗЬ',
  зьо: 'ЗЬО',
  й: 'Й',
  і: 'іграшки',
  ї1: 'їжак',
  ї: 'їжа',
  к: 'К',
  ка: 'кактус',
  ке: 'келих',
  ки1: 'кит',
  ки: 'килим',
  кі: 'ківі',
  кі1: 'кіготь',
  ко: 'кошик',
  ку: 'кубик',
  л: 'Л',
  ла: 'лава',
  ле: 'лезо',
  ли: 'листя',
  лі: 'лійка',
  ло: 'ложка',
  лу: 'лупа',
  лю: 'люстра',
  ля: 'лялька',
  ль: 'ЛЬ',
  льо: 'ЛЬО',
  м: 'М',
  'м’я': 'М’Я',
  ма: 'маска',
  ме: 'мед',
  ми: 'миша',
  мі: 'місяць',
  мо: 'морква',
  му: 'мушля',
  н: 'Н',
  на: 'напій',
  ни: 'нитки',
  ні1: 'ніготь',
  ні: 'ніж',
  но: 'ножиці',
  ня: 'няня',
  нь: 'НЬ',
  ньо: 'НЬО',
  о1: 'олень',
  о: 'око',
  п: 'П',
  'п’я': 'П’Я',
  па: 'палець',
  пе: 'печиво',
  пи: 'писанка',
  пі: 'півень',
  по: 'поїзд',
  пу: 'пудель',
  р: 'Р',
  'р’ї': 'Р’Ї',
  'р’є': 'Р’Є',
  'р’я': 'Р’Я',
  ра: 'рак',
  ре: 'ремінь',
  ри: 'рис',
  рі: 'ріг',
  ро: 'робот',
  ру: 'ручка',
  рю: 'рюмка',
  ря: 'ряска',
  с: 'С',
  са: 'сани',
  се: 'серце',
  си: 'сито',
  сі: 'сіно',
  со: 'сонце',
  су: 'сумка',
  ся: 'сяйво',
  сь: 'СЬ',
  т: 'Т',
  та: 'танк',
  те: 'терка',
  ти: 'тигр',
  ті: 'тісто',
  то: 'торт',
  ту: 'туфлі',
  /*тукан тумба тур туз туфля туш*/ тю: 'тюбик',
  ть: 'ТЬ',
  у: 'учень',
  ф: 'Ф',
  фа: 'фарба',
  фі: 'фільм',
  фе: 'фея',
  фо: 'фокусник',
  х: 'Х',
  ха: 'хата',
  хи: 'хижка',
  хо: 'хобот',
  ху: 'хустка',
  ц: 'Ц',
  ца: 'цап',
  це: 'цегла',
  ци: 'цифри',
  ці: 'ціль',
  цу: 'цуцик',
  ця: 'цятка',
  ць: 'ЦЬ',
  цьо: 'ЦЬО',
  ч: 'Ч',
  ча: 'чайка',
  че1: 'чешки',
  че: 'череп',
  чи: 'чиж',
  чі: 'чіпчик',
  чо: 'човен',
  чу: 'чуб',
  ш: 'Ш',
  'ш’ю': 'Ш’Ю',
  ша1: 'шабля',
  ша: 'шапка',
  ше: 'шершень',
  ші: 'шість',
  ши: 'шишка',
  шо: 'шорти',
  шу: 'шуба',
  щ: 'Щ',
  ща: 'щастя',
  щи: 'щит',
  щі: 'щітка',
  щу: 'щука',
  я: 'яблуко',
  А: 'А',
  Б: 'Б',
  В: 'В',
  Г: 'Г',
  Ґ: 'Ґ',
  Д: 'Д',
  Є: 'Є',
  Ж: 'Ж',
  З: 'З',
  Й: 'Й',
  І: 'І',
  Ї: 'Ї',
  К: 'К',
  Л: 'Л',
  М: 'М',
  Н: 'Н',
  О: 'О',
  П: 'П',
  Р: 'Р',
  С: 'С',
  Т: 'Т',
  У: 'У',
  Ф: 'Ф',
  Х: 'Х',
  Ц: 'Ц',
  Ч: 'Ч',
  Ш: 'Ш',
  Щ: 'Щ',
  Я: 'Я',
  БА: 'БА',
  БЕ: 'БЕ',
  БИ: 'БИ',
  БІ: 'БІ',
  БО: 'БО',
  БУ: 'БУ',
  ВА: 'ВА',
  ВЕ: 'ВЕ',
  ВИ: 'ВИ',
  ВІ: 'ВІ',
  ВО: 'ВО',
  ВУ: 'ВУ',
  ГА: 'ГА',
  ГИ: 'ГИ',
  ГІ: 'ГІ',
  ГО: 'ГО',
  ГУ: 'ГУ',
  ДА: 'ДА',
  ДЕ: 'ДЕ',
  ДИ: 'ДИ',
  ДІ: 'ДІ',
  ДО: 'ДО',
  ДУ: 'ДУ',
  ДЯ: 'ДЯ',
  ДЬ: 'ДЬ',
  ЖА: 'ЖА',
  ЖЕ: 'ЖЕ',
  ЖИ: 'ЖИ',
  ЖІ: 'ЖІ',
  ЖО: 'ЖО',
  ЖУ: 'ЖУ',
  ЗА: 'ЗА',
  ЗЕ: 'ЗЕ',
  ЗІ: 'ЗІ',
  ЗИ: 'ЗИ',
  ЗО: 'ЗО',
  ЗУ: 'ЗУ',
  ЗЬ: 'ЗЬ',
  КА: 'КА',
  КЕ: 'КЕ',
  КИ: 'КИ',
  КІ: 'КІ',
  КО: 'КО',
  КУ: 'КУ',
  ЛА: 'ЛА',
  ЛЕ: 'ЛЕ',
  ЛИ: 'ЛИ',
  ЛІ: 'ЛІ',
  ЛО: 'ЛО',
  ЛУ: 'ЛУ',
  ЛЮ: 'ЛЮ',
  ЛЯ: 'ЛЯ',
  ЛЬ: 'ЛЬ',
  МА: 'МА',
  МЕ: 'МЕ',
  МИ: 'МИ',
  МІ: 'МІ',
  МО: 'МО',
  МУ: 'МУ',
  НА: 'НА',
  НИ: 'НИ',
  НІ: 'НІ',
  НО: 'НО',
  НЯ: 'НЯ',
  НЬ: 'НЬ',
  ПА: 'ПА',
  ПЕ: 'ПЕ',
  ПИ: 'ПИ',
  ПІ: 'ПІ',
  ПО: 'ПО',
  ПУ: 'ПУ',
  РА: 'РА',
  РЕ: 'РЕ',
  РИ: 'РИ',
  РІ: 'РІ',
  РО: 'РО',
  РУ: 'РУ',
  РЮ: 'РЮ',
  РЯ: 'РЯ',
  СА: 'СА',
  СЕ: 'СЕ',
  СИ: 'СИ',
  СІ: 'СІ',
  СО: 'СО',
  СУ: 'СУ',
  СЯ: 'СЯ',
  СЬ: 'СЬ',
  ТА: 'ТА',
  ТЕ: 'ТЕ',
  ТИ: 'ТИ',
  ТІ: 'ТІ',
  ТО: 'ТО',
  ТУ: 'ТУ',
  ТЮ: 'ТЮ',
  ТЬ: 'ТЬ',
  ФА: 'ФА',
  ФЕ: 'ФЕ',
  ФІ: 'ФІ',
  ФО: 'ФО',
  ХА: 'ХА',
  ХИ: 'ХИ',
  ХО: 'ХО',
  ХУ: 'ХУ',
  ЦА: 'ЦА',
  ЦЕ: 'ЦЕ',
  ЦИ: 'ЦИ',
  ЦІ: 'ЦІ',
  ЦУ: 'ЦУ',
  ЦЯ: 'ЦЯ',
  ЦЬ: 'ЦЬ',
  ЧА: 'ЧА',
  ЧЕ: 'ЧЕ',
  ЧИ: 'ЧИ',
  ЧІ: 'ЧІ',
  ЧО: 'ЧО',
  ЧУ: 'ЧУ',
  ША: 'ША',
  ШЕ: 'ШЕ',
  ШИ: 'ШИ',
  ШО: 'ШО',
  ШІ: 'ШІ',
  ШУ: 'ШУ',
  ЩА: 'ЩА',
  ЩИ: 'ЩИ',
  ЩІ: 'ЩІ',
  ЩУ: 'ЩУ',
  'Б’Я': 'Б’Я',
  'В’Ї': 'В’Ї',
  'В’Є': 'В’Є',
  'В’Я': 'В’Я',
  ЗЬО: 'ЗЬО',
  ЛЬО: 'ЛЬО',
  'М’Я': 'М’Я',
  'П’Ю': 'П’Ю',
  'П’Я': 'П’Я',
  'Р’Є': 'Р’Є',
  'Р’Ї': 'Р’Ї',
  'Р’Я': 'Р’Я',
  ЦЬО: 'ЦЬО',
  НЬО: 'НЬО',
  'Ш’Ю': 'Ш’Ю',
};

const quizAnswersArray = [
  'абетка',
  'акула',
  'акули',
  'ананас',
  'ананаси',
  'бабаки',
  'бабуся',
  'банани',
  'банка',
  'барабан',
  'барабани',
  'барани',
  'батарея',
  'батько',
  'берег',
  'береги',
  'бивень',
  'бивні',
  'біг',
  'бігуни',
  'білочка',
  'білченя',
  'блоха',
  'бобри',
  'богомол',
  'богомоли',
  'болото',
  'борода',
  'боротьба',
  'брови',
  'будинки',
  'букети',
  'булава',
  'бур’яни',
  'буряки',
  'вагон',
  'вагони',
  'ваза',
  'валіза',
  'варани',
  'васильок',
  'вафлі',
  'ведмеді',
  'ведмідь',
  'велетень',
  'велетні',
  'весла',
  'вибух',
  'вибухи',
  'виделка',
  'вишні',
  'відро',
  'віз',
  'візки',
  'вісім',
  'віяло',
  'вогні',
  'вогонь',
  'водій',
  'водії',
  'водопій',
  'ворота',
  'вуж',
  'вулики',
  'газ',
  'газети',
  'гайка',
  'галушки',
  'гараж',
  'гаражі',
  'гарбуз',
  'гарбузи',
  'гачки',
  'гієна',
  'гілки',
  'гітара',
  'гітари',
  'глина',
  'гноми',
  'гномик',
  'голова',
  'голуб’я',
  'гора',
  'гори',
  'горила',
  'горіх',
  'горіхи',
  'горошина',
  'груша',
  'гусь',
  'два',
  'двері',
  'дев’ять',
  'дзьоби',
  'димарі',
  'динька',
  'дитина',
  'дівчина',
  'дідусі',
  'дідусь',
  'долоні',
  'долоня',
  'доміно',
  'дорога',
  'дороги',
  'дощ',
  'дощі',
  'дрова',
  'дуб',
  'дудка',
  'духовка',
  'душ',
  'дядько',
  'єдинороги',
  'жало',
  'жереб’я',
  'жирафа',
  'жито',
  'жолуді',
  'жолудь',
  'жук',
  'жуки',
  'заводи',
  'залізо',
  'замки',
  'заноза',
  'зарості',
  'земля',
  'зернята',
  'злива',
  'змія',
  'зозуля',
  'зорі',
  'зуб',
  'ібіси',
  'ігуана',
  'їжа',
  'їжаченя',
  'ікла',
  'ікона',
  'індик',
  'індики',
  'іній',
  'кавуни',
  'кажан',
  'кажани',
  'какаду',
  'калина',
  'калюжа',
  'канапа',
  'капелюх',
  'капелюхи',
  'капець',
  'капуста',
  'капці',
  'карта',
  'каченя',
  'каша',
  'квитки',
  'квиток',
  'кеглі',
  'кеди',
  'кенгуру',
  'кеш’ю',
  'килим',
  'килими',
  'кити',
  'киця',
  'ківі',
  'кіготь',
  'кінь',
  'книга',
  'коала',
  'коали',
  'кобра',
  'ковалі',
  'коваль',
  'ковзани',
  'коза',
  'козак',
  'козаки',
  'козеня',
  'кози',
  'кокос',
  'кокоси',
  'колесо',
  'колібрі',
  'колії',
  'колія',
  'коло',
  'колода',
  'колони',
  'колос',
  'кольори',
  'комар',
  'коники',
  'коні',
  'копито',
  'корал',
  'корали',
  'коробка',
  'корова',
  'корови',
  'корона',
  'коса',
  'косуля',
  'кофта',
  'кран',
  'крем',
  'крила',
  'кролик',
  'кролики',
  'кулаки',
  'куля',
  'кульбаба',
  'кулька',
  'кульок',
  'куниця',
  'курага',
  'кури',
  'кухарі',
  'кущ',
  'лаваш',
  'лампа',
  'ласощі',
  'лби',
  'лебеді',
  'лебідь',
  'лев',
  'левеня',
  'левиця',
  'лелека',
  'лелеки',
  'лижі',
  'лимон',
  'лимонад',
  'лимони',
  'лисеня',
  'лисиця',
  'лицарі',
  'ліани',
  'ліжко',
  'лікарі',
  'лісовоз',
  'літаки',
  'лобзик',
  'лопата',
  'лопати',
  'лось',
  'люди',
  'льодяник',
  'льон',
  'м’ясо',
  'мавпа',
  'макака',
  'макаки',
  'малина',
  'малюнки',
  'малята',
  'мама',
  'машина',
  'медуза',
  'медузи',
  'мелодія',
  'меч',
  'міліція',
  'місяць',
  'молодець',
  'молоко',
  'молотки',
  'молоток',
  'монітор',
  'море',
  'моряки',
  'мотори',
  'мох',
  'музей',
  'музеї',
  'мурашка',
  'намети',
  'намисто',
  'насінина',
  'насінини',
  'нігті',
  'ніж',
  'носоріг',
  'носороги',
  'ноти',
  'оаза',
  'овал',
  'овали',
  'овечка',
  'овоч',
  'овочі',
  'одяг',
  'ожина',
  'озеро',
  'око',
  'окуляри',
  'олені',
  'олень',
  'оси',
  'осінь',
  'очі',
  'п’явка',
  'п’ятачок',
  'п’ять',
  'павич',
  'павичі',
  'павуки',
  'пазурі',
  'пакети',
  'палац',
  'палаци',
  'паляниця',
  'пальма',
  'пальто',
  'панама',
  'папуга',
  'папужка',
  'парасоля',
  'перець',
  'печиво',
  'пила',
  'пили',
  'піаніно',
  'піжама',
  'пікнік',
  'піна',
  'пір’їна',
  'пір’я',
  'піраміда',
  'пітони',
  'піца',
  'піч',
  'плече',
  'пов’язка',
  'погода',
  'пожежа',
  'полуниця',
  'поля',
  'порося',
  'посуд',
  'пряжа',
  'пси',
  'пух',
  'ракета',
  'ракети',
  'ракетка',
  'редька',
  'рейки',
  'ремінь',
  'реп’яхи',
  'риба',
  'рисі',
  'рись',
  'різьба',
  'ріпка',
  'роги',
  'родина',
  'розмова',
  'ромашки',
  'рот',
  'рукавиця',
  'рюкзак',
  'сад',
  'самокат',
  'самокати',
  'сані',
  'селезень',
  'селезні',
  'селяни',
  'силач',
  'синиця',
  'сир',
  'сіль',
  'сім',
  'сіно',
  'слива',
  'слон',
  'сльоза',
  'собака',
  'собаки',
  'сова',
  'сови',
  'соки',
  'сокіл',
  'солдат',
  'солдати',
  'солов’ї',
  'соловей',
  'сонях',
  'соняхи',
  'сорока',
  'соска',
  'стіл',
  'столи',
  'сукня',
  'сумка',
  'суп',
  'сутичка',
  'тато',
  'тачка',
  'телефони',
  'тетерев',
  'тінь',
  'томати',
  'трава',
  'труба',
  'тумба',
  'туфелька',
  'тюль',
  'удав',
  'удави',
  'уроки',
  'факел',
  'фермер',
  'фляги',
  'фольга',
  'хвиля',
  'хижаки',
  'хліб',
  'хлібина',
  'хлоп’я',
  'хмара',
  'ховрах',
  'ховрахи',
  'хор',
  'хутро',
  'цап',
  'цвіт',
  'цибулина',
  'цибуля',
  'цитрус',
  'цьом',
  'чаєня',
  'чай',
  'чайник',
  'чайники',
  'чапля',
  'чародії',
  'чашка',
  'черв’яки',
  'черевики',
  'череп',
  'черепаха',
  'черепи',
  'чиж',
  'чобітки',
  'чоботи',
  'човен',
  'чотири',
  'шаблі',
  'шапка',
  'шарф',
  'шахи',
  'шви',
  'шило',
  'шина',
  'шов',
  'шоколад',
  'шолом',
  'шпага',
  'штора',
  'шум',
  'шухляда',
  'щиголь',
  'щука',
  'щур',
  'ябеда',
  'яблука',
  'яйце',
  'ялина',
  'ялини',
  'яма',
  'ящірка',
  'яю',
];

const vowelArray = [
  'а',
  'е',
  'и',
  'і',
  'о',
  'у',
  'ї',
  'є',
  'ю',
  'я',
  'й',
  'ы',
  'э',
  'А',
  'Е',
  'И',
  'І',
  'О',
  'У',
  'Ї',
  'Є',
  'Ю',
  'Я',
  'Ы',
  'Э',
  'Й',
];

const consonantArray = [
  'б',
  'в',
  'г',
  'ґ',
  'д',
  'ж',
  'з',
  'й',
  'к',
  'л',
  'м',
  'н',
  'п',
  'р',
  'с',
  'т',
  'ф',
  'х',
  'ц',
  'ч',
  'ш',
  'щ',
  'ъ',
  'Б',
  'В',
  'Г',
  'Ґ',
  'Д',
  'Ж',
  'З',
  'Й',
  'К',
  'Л',
  'М',
  'Н',
  'П',
  'Р',
  'С',
  'Т',
  'Ф',
  'Х',
  'Ц',
  'Ч',
  'Ш',
  'Щ',
  'Ъ',
  'м’я',
  'р’я',
  'п’я',
  'р’ї',
  'б’я',
  'в’ї',
  'в’я',
  'ш’ю',
  'льо',
  'цьо',
  'зьо',
  'ль',
  'нь',
  'дь',
  'ть',
  'зь',
  'сь',
  'ць',
];

export {
  transliterateDictionary,
  transliteratedIdDictionary,
  wordBySyllableDictionary,
  quizAnswersArray,
  vowelArray,
  consonantArray,
};
