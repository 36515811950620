import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from '../../../../../atoms/Image';

import {
  transliterate,
  getQuizAnswers,
} from './../../../../../../utils/Language/LanguageAnalysis';
import './styles.scss';

class QuizImage extends Component {
  constructor(props) {
    super(props);
    this.state = { quizAnswers: [], ...props };
  }

  componentDidMount() {
    this.setState({
      quizAnswers: getQuizAnswers(this.state.word),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ quizCunningMode: nextProps.quizCunningMode });
  }

  toggleQuizCunningMode = () => {
    this.setState({ quizCunningMode: !this.state.quizCunningMode });
  };

  checkAnswer = (e, answer) => {
    if (
      this.state.word.toLowerCase().replace('1', '') ===
      answer.toLowerCase()
    ) {
      this.props.next();
    } else {
      // TODO: Implement interaction
      console.log('Та ні, правильно не «' + answer + '»!');
    }
  };

  render() {
    let cunningModeClass = this.state.quizCunningMode
      ? ' cunning-mode-on'
      : '';
    return this.state.word ? (
      <ul className={'quiz-images' + cunningModeClass}>
        {this.state.quizCunningMode && (
          <div
            className="cunning-mode-overlay"
            onClick={this.toggleQuizCunningMode}
            alt="Показати відповіді й ключі"
            title="Показати відповіді й ключі"
          />
        )}
        {this.state.quizAnswers.map((answer, index) => {
          let picturePath = transliterate(answer);
          return (
            <li key={answer + index}>
              {/* <Image src={{ uri: 'http://i.imgur.com/random.jpg' }} /> */}
              <Image
                filename={`${picturePath}.jpg`}
                alt={answer}
                title={answer}
                onClick={(e) => this.checkAnswer(e, answer)}
              />
            </li>
          );
        }, this)}
      </ul>
    ) : null;
  }
}

QuizImage.propTypes = {
  word: PropTypes.string,
  cunningMode: PropTypes.bool,
  toggleCardCunningMode: PropTypes.func,
};

export default QuizImage;
