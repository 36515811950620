import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const PodarunkoviUrokyZakinchylysya = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>
        {renderHTML(strings._10PodarunkoviUrok)}{' '}
        <span style={{ textDecoration: 'line-through' }}>
          {' '}
          {renderHTML(strings._20Zakinchylysya)}
        </span>
        {renderHTML(strings._30AleMozhnaVidkry)}
      </h1>

      <p>
        <strong>{renderHTML(strings._40SpershuYaDumavD)}</strong>{' '}
        {renderHTML(strings._50PeredVamyShcheT)}{' '}
        <strong>{renderHTML(strings._60Podobayetsya)}</strong>{' '}
        {renderHTML(strings._70NaKnoptsiFacebo)}{' '}
        <strong>{renderHTML(strings._80ZhoryPravoruch)}</strong>
      </p>
      <p>{renderHTML(strings._90RanisheTutBulyK)}</p>
      <p>
        {renderHTML(strings._100IShcheLysheProt)}{' '}
        <strong>{renderHTML(strings._110TakDeshevoNeBud)}</strong>
      </p>

      <NextSlideButton
        text={strings._120OznayomytysZRoz}
        card={props}
      />

      <p>{renderHTML(strings._130VidklastyPropoz)}</p>
      <NextSlideButton
        text={strings._140VidkrytyTryDoda}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

PodarunkoviUrokyZakinchylysya.propTypes = {
  props: PropTypes.object.isRequired,
};

export default PodarunkoviUrokyZakinchylysya;
