import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const YakHratysya = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10YakHratysya)}</h1>
      <p>{renderHTML(strings._20PeredVamyVisim)}</p>
      <p>{renderHTML(strings._30NahadayemoPravyla)}</p>
      <p>{renderHTML(strings._40VidSlovaMyBeremo)}</p>
      <p>{renderHTML(strings._50UseShchoPotribnoZrobyty)}</p>
      <ul>{renderHTML(strings._60MaskaMaVaflyaVa)}</ul>
      <p>{renderHTML(strings._70TutMozhnaNenadovho)}</p>
      <ul>{renderHTML(strings._80LiReminRe)}</ul>
      <p>{renderHTML(strings._90MozhnaHratysyaSlovamy)}</p>
      <ul>{renderHTML(strings._100VanyaVaTanyaTa)}</ul>
      <NextSlideButton
        text={strings._110ProdovzhytyHru}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

YakHratysya.propTypes = {
  props: PropTypes.object.isRequired,
};

export default YakHratysya;
