import React from 'react';

import './../styles.scss';

const NextSlideButton = ({ text, card }) => {
  return (
    <div>
      <a onClick={card.next} className="slide-button">
        {text}
      </a>
    </div>
  );
};

export default NextSlideButton;
