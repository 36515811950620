import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const Chudovo = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10Chudovo)}</h1>

      <p>{renderHTML(strings._20AlePodumayeshD)}</p>

      <ul>
        <li>{renderHTML(strings._30KoshykRobotVa)}</li>
        <li>{renderHTML(strings._40KoshykRobotNa)}</li>
        <li>{renderHTML(strings._50KaktusLystyaN)}</li>
        <li>{renderHTML(strings._60DymTyhrNapiy)}</li>
      </ul>

      <NextSlideButton
        text={strings._70ATeperZKartynka}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

Chudovo.propTypes = {
  props: PropTypes.object.isRequired,
};

export default Chudovo;
