import React from 'react';
import { Link } from 'gatsby';
import { LESSONS_PLAN } from '../../../../constants/routes';

import './../styles.scss';

const GoToLessonsPlanButton = ({ text }) => {
  return (
    <div>
      <Link
        to={LESSONS_PLAN}
        className="slide-button"
        activeClassName="active"
      >
        {text}
      </Link>
    </div>
  );
};

export default GoToLessonsPlanButton;
