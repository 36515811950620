import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const ZnayemoSchoRobyty = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10ZnayemoShchoRob)}</h1>

      <p>
        <strong>{renderHTML(strings._20YakIMynulohoRaz)}</strong>{' '}
        {renderHTML(strings._30RozshyryuyemoSl)}{' '}
      </p>
      <p>
        {renderHTML(strings._40VazhlyvoShchobU)}{' '}
        <strong> {renderHTML(strings._50PromovlyavSamos)}</strong>{' '}
        {renderHTML(strings._60VaflyaZayetsV)}
      </p>
      <p>
        {renderHTML(strings._70DuzheDobreATepe)}{' '}
        <strong> {renderHTML(strings._80PominyaytesyaRo)}</strong>{' '}
        {renderHTML(strings._90DytynaTakSamoVh)}
      </p>

      <NextSlideButton
        text={strings._100RozpochatyHru}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

ZnayemoSchoRobyty.propTypes = {
  props: PropTypes.object.isRequired,
};

export default ZnayemoSchoRobyty;
