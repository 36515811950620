import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';
import GoToSlideButton from './../../../../../components/atoms/Buttons/GoToSlideButton';

const YakPochynavsyaRebusMetod = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10YakPochynavsyaR)}</h1>
      <p>{renderHTML(strings._20PerehlyanteVide)}</p>
      <p>{renderHTML(strings._30DytsadokUMinsku)}</p>
      <NextSlideButton
        text={strings._40PerehlyanutyVid}
        card={props}
      />
      <p>{renderHTML(strings._50Abo)}</p>
      <GoToSlideButton
        text={strings._60ProdovzhytyNavc}
        card={props}
        destinationSlide={3}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

YakPochynavsyaRebusMetod.propTypes = {
  props: PropTypes.object.isRequired,
};

export default YakPochynavsyaRebusMetod;
