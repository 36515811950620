import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import GoToLessonsPlanButton from './../../../../../components/atoms/Buttons/GoToLessonsPlanButton';

const Podobayetsya = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10Podobayetsya)}</h1>
      <p>
        <strong>{renderHTML(strings._20PodilitsyaVrazh)}</strong>
      </p>
      <ul>
        <li>{renderHTML(strings._30ZYakoyuProblemo)}</li>
        <li>{renderHTML(strings._40YakiBulySumnivy)}</li>
        <li>{renderHTML(strings._50YakiPotochniRez)}</li>
      </ul>
      HyperComments
      <GoToLessonsPlanButton
        text={renderHTML(strings._60ZakinchytyUrok)}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

Podobayetsya.propTypes = {
  props: PropTypes.object.isRequired,
};

export default Podobayetsya;
