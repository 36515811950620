import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from '../../../../../atoms/Image';

import Sound from '../../../../../../utils/react-sound';

import DominoLetters from './../DominoLetters';
import Hint from './../Hint';
import {
  getTransliteratedId,
  getWordBySyllable,
  divideBySyllables,
  isNotConsonant,
} from './../../../../../../utils/Language/LanguageAnalysis';
import './styles.scss';

export default class Domino extends Component {
  constructor(props) {
    super(props);
    this.state = { isPlaying: {}, syllablesArray: [], ...props };
    // soundManager.setup({
    //   useConsole: false,
    //   debugMode: false,
    //   consoleOnly: false,
    //   debugFlash: false,
    //   ignoreMobileRestrictions: true,
    // });
  }

  componentDidMount() {
    let isPlaying = {};
    let syllablesArray = this.props.syllables
      ? divideBySyllables(this.props.syllables)
      : null;
    syllablesArray.forEach((syllable) => {
      isPlaying[getTransliteratedId(syllable)] = Sound.status.STOPPED;
    });
    this.setState({
      isPlaying,
      syllablesArray,
    });
  }

  onPlaySound = (syllable) => {
    let isPlaying = {};
    this.state.syllablesArray.forEach((syl) => {
      isPlaying[getTransliteratedId(syl)] = Sound.status.STOPPED;
    });
    isPlaying[syllable] = Sound.status.PLAYING;
    this.setState({ isPlaying: isPlaying });
  };
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ keysCunningMode: nextProps.keysCunningMode });
  }

  toggleKeysCunningMode = () => {
    this.setState({ keysCunningMode: !this.state.keysCunningMode });
  };

  render() {
    let cunningModeClass = this.state.keysCunningMode
      ? ' cunning-mode-on'
      : '';
    return this.props.syllables ? (
      <div>
        <ul
          className={
            'domino-images ' + this.props.size + cunningModeClass
          }
        >
          {this.state.keysCunningMode && (
            <div
              className="cunning-mode-overlay"
              onClick={this.toggleKeysCunningMode}
              alt="Показати ключі й відповіді"
              title="Показати ключі й відповіді"
            />
          )}
          {this.state.syllablesArray.map((syllable, index) => {
            const transliteratedId = getTransliteratedId(syllable);
            return (
              <li
                key={syllable + index}
                onMouseEnter={() => this.onPlaySound(transliteratedId)}
              >
                {isNotConsonant(syllable) && (
                  <Image filename={`${transliteratedId}.jpg`} />
                )}
                <DominoLetters
                  text={syllable.toUpperCase()}
                  size={this.props.size}
                  isConsonant={
                    isNotConsonant(syllable) ? '' : ' consonant'
                  }
                />
                <Hint
                  text={getWordBySyllable(syllable)}
                  shaded={false}
                />
                <Sound
                  url={`https://www.rebusmetod.com/wp-content/themes/whiteboard/sounds/${transliteratedId}01.ogg`}
                  autoload={true}
                  playStatus={
                    this.state.isPlaying[transliteratedId] //or try adding ogg to webpack and reference the file with require
                  }
                />
              </li>
            );
          }, this)}
        </ul>
      </div>
    ) : null;
  }
}

Domino.propTypes = {
  syllables: PropTypes.string,
  keysCunningMode: PropTypes.bool,
  toggleCardCunningMode: PropTypes.func,
};
