import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const DytsadokUMinsku = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10DytsadokUMinsku)}</h1>

      <p>{renderHTML(strings._20AvtorMetodykyLe)}</p>
      {renderHTML(strings._30YouTubeLink)}
      <NextSlideButton
        text={strings._40ProdovzhytyNavc}
        card={props}
      />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

DytsadokUMinsku.propTypes = {
  props: PropTypes.object.isRequired,
};

export default DytsadokUMinsku;
