import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import NextSlideButton from './../../../../../components/atoms/Buttons/NextSlideButton';

const ShchoDali = ({ props }) => {
  const strings = props.article.strings;
  return props.article ? (
    <div className="article">
      <h1>{renderHTML(strings._10ShchoDali)}</h1>
      <p>{renderHTML(strings._20PyatHvylynVese)}</p>
      <p>{renderHTML(strings._30ZKilkohSlivHuc)}</p>
      <p>{renderHTML(strings._40TakSamoBezPoyas)}</p>
      <ul>{renderHTML(strings._50MaskaMaska)}</ul>
      <NextSlideButton text={strings._60NumoDoHry} card={props} />
    </div>
  ) : (
    <div>"Завантажую..."</div>
  );
};

ShchoDali.propTypes = {
  props: PropTypes.object.isRequired,
};

export default ShchoDali;
