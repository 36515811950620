import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Hint = ({ text, shaded }) => {
  const shadedClass = shaded ? 'shaded' : '';
  return text ? (
    <div className={'hint ' + shadedClass}>{text}</div>
  ) : null;
};

Hint.propTypes = {
  text: PropTypes.string.isRequired,
  shaded: PropTypes.bool,
};

export default Hint;
