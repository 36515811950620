import {
  transliterateDictionary,
  transliteratedIdDictionary,
  wordBySyllableDictionary,
  quizAnswersArray,
  vowelArray,
  consonantArray,
} from './WordDictionaries';

const replaceWithObjectMatches = (str, mapObj) => {
  let regExp = new RegExp(Object.keys(mapObj).join('|'), 'gi');
  return str.replace(regExp, function (matched) {
    return mapObj[matched];
  });
};

const transliterate = (str) => {
  // was: translitIt
  return replaceWithObjectMatches(str, transliterateDictionary);
};

const getTransliteratedId = (str) => {
  // was: getpicture
  return transliteratedIdDictionary[str];
};

const getWordBySyllable = (str) => {
  // was: getword
  return wordBySyllableDictionary[str];
};

const testLowerCase = (syllable) => {
  return syllable == syllable.toLowerCase() ? true : false;
};

const getQuizAnswers = (correctAnswer, exceptions) => {
  //was getwronganswer
  correctAnswer = correctAnswer.toLowerCase();

  Array.prototype.diff = function (arr) {
    return this.filter((item) => {
      return arr.indexOf(item) < 0;
    });
  };

  function getHash(string) {
    //передбачуване випадкове сортування відповідей на щоденній
    //основі. допоки слайд-бібліотека при виклику history.push,
    //переініціалізовує компоненти карток, я вирішив, сортувати
    //відповіді на основі хешу слів-відповідей та поточної дати
    let today = new Date();
    let date = today.getDate();
    string += date;
    return string.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return Math.abs(a & a);
    }, 0);
  }

  exceptions = exceptions ? exceptions : [];
  let quizAnswers = quizAnswersArray.slice();

  let correctCurrentWord = correctAnswer.replace(/’|1/gi, ''); //чистка від одиниць і апострофів
  while (correctCurrentWord.length > 0) {
    //розбираємо по літері
    let currentCharacter = correctCurrentWord.substring(0, 1);
    //якщо знайшли таку літеру в масиві, маркуємо її X
    quizAnswers.map((quizAnswer) => {
      let RegExpession = new RegExp(currentCharacter, 'g');
      let currentCharacterOccurrences =
        quizAnswer !== correctAnswer
          ? (quizAnswer.match(RegExpession) || []).length
          : 100;
      let sortingWeightCoefficient = '';
      for (let x = 1; x === currentCharacterOccurrences; x++) {
        sortingWeightCoefficient += 'X';
      }
      quizAnswers[quizAnswers.indexOf(quizAnswer)] =
        currentCharacterOccurrences > 0
          ? sortingWeightCoefficient + quizAnswer
          : quizAnswer;
    });
    correctCurrentWord = correctCurrentWord.substring(1);
  }
  quizAnswers.sort(); //складаємо рейтинг схожості
  quizAnswers = quizAnswers.slice(0, 6); //зрізаємо перші вершки, щоб потім менше часу йшло на заміну
  quizAnswers.unshift(correctAnswer);
  quizAnswers.map((quizAnswer) => {
    //чистимо від маркерів
    quizAnswers[quizAnswers.indexOf(quizAnswer)] = quizAnswer.replace(
      /X/gi,
      '',
    );
    //і виключень складів
    quizAnswers[quizAnswers.indexOf(quizAnswer)] = quizAnswer.replace(
      /1/gi,
      '',
    );
  });
  //видаляємо виключення, які збивають діток
  quizAnswers = quizAnswers.diff(exceptions);
  //додаємо хеш для сортування
  quizAnswers.map((quizAnswer) => {
    quizAnswers[quizAnswers.indexOf(quizAnswer)] =
      getHash(quizAnswer) +
      '.' +
      quizAnswers[quizAnswers.indexOf(quizAnswer)];
  });
  quizAnswers.sort(); //сортуємо по хешу
  //прибираємо хеш
  quizAnswers.map((quizAnswer) => {
    //чистимо від маркерів
    quizAnswers[
      quizAnswers.indexOf(quizAnswer)
    ] = quizAnswer.substring(quizAnswer.indexOf('.') + 1);
  });
  quizAnswers.map((quizAnswer) => {
    //Перша літера велика
    quizAnswers[quizAnswers.indexOf(quizAnswer)] =
      quizAnswer.substring(0, 1).toUpperCase() +
      quizAnswer.substring(1);
  });
  let sortresults = quizAnswers.slice(0, 7); //зрізаємо вершки
  return sortresults;
};

const divideBySyllables = (currentWord) => {
  //was decompileword
  let vowel = vowelArray;
  let dashedWord = [];

  while (currentWord.length > 0) {
    //перша голосна?
    if (vowel.indexOf(currentWord.substring(0, 1)) >= 0) {
      if (currentWord.substring(1, 2) === '1') {
        //перший альтернативний склад?
        dashedWord.push(currentWord.substring(0, 2));
        currentWord = currentWord.substring(2);
      } else {
        dashedWord.push(currentWord.substring(0, 1));
        currentWord = currentWord.substring(1);
      }
    } //отже приголосна
    else {
      //друга голосна?
      if (vowel.indexOf(currentWord.substring(1, 2)) >= 0) {
        if (currentWord.substring(2, 3) === '1') {
          //альтернативний склад? забираємо одразу три
          dashedWord.push(currentWord.substring(0, 3));
          currentWord = currentWord.substring(3);
        } else {
          dashedWord.push(currentWord.substring(0, 2)); //забираємо одразу дві
          currentWord = currentWord.substring(2);
        }
      } else {
        //забираємо приголосну
        if (currentWord.substring(1, 3).toLowerCase() === 'ьо') {
          //а раптом це "льо"?
          dashedWord.push(currentWord.substring(0, 3));
          currentWord = currentWord.substring(3);
        } else {
          if (
            currentWord.substring(1, 2).toLowerCase() === 'ь' ||
            currentWord.substring(0, 2).toLowerCase() === 'дж' ||
            currentWord.substring(0, 2).toLowerCase() === 'дз'
          ) {
            dashedWord.push(currentWord.substring(0, 2));
            currentWord = currentWord.substring(2);
          } else {
            if (currentWord.substring(1, 2) === '’') {
              dashedWord.push(currentWord.substring(0, 3));
              currentWord = currentWord.substring(3);
            } else {
              //таки просто приголосна
              dashedWord.push(currentWord.substring(0, 1));
              currentWord = currentWord.substring(1);
            }
          }
        }
      }
    }
  }
  return dashedWord;
};

const isNotConsonant = (syllable) => {
  return consonantArray.indexOf(syllable.toLowerCase()) < 0;
};

export {
  transliterate,
  getTransliteratedId,
  getWordBySyllable,
  testLowerCase,
  getQuizAnswers,
  divideBySyllables,
  isNotConsonant,
};
