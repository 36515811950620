import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../../../../atoms/Image';

import Letters from './../Letters';
import Hint from './../Hint';
import {
  getTransliteratedId,
  getWordBySyllable,
  testLowerCase,
  divideBySyllables,
  isNotConsonant,
} from './../../../../../../utils/Language/LanguageAnalysis';
import './styles.scss';

const Syllables = ({ word, type }) => {
  let syllablesArray = word ? divideBySyllables(word) : null;
  return word ? (
    <ul className="syllables">
      {syllablesArray.map((syllable, index) => {
        let picturePath = getTransliteratedId(syllable);
        return (
          <li key={syllable + index}>
            {/* <Image src={{ uri: 'http://i.imgur.com/random.jpg' }} /> */}
            {testLowerCase(syllable) && isNotConsonant(syllable) ? (
              <Image filename={`${picturePath}.jpg`} />
            ) : (
              <Letters text={syllable.toUpperCase()} size="lg" />
            )}
            {type === 'read' ? (
              <Letters
                text={syllable.toUpperCase().replace('1', '')}
                size="md"
              />
            ) : null}
            <Hint text={getWordBySyllable(syllable)} shaded={true} />
          </li>
        );
      })}
    </ul>
  ) : null;
};

Syllables.propTypes = {
  word: PropTypes.string,
  type: PropTypes.string,
};

export default Syllables;
